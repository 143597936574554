import React, { useEffect } from 'react'
import Prism from 'prismjs'
import { FaChevronRight, FaCloudDownloadAlt, FaExternalLinkAlt } from "react-icons/fa";
import sql from 'prismjs/components/prism-sql'
import json from 'prismjs/components/prism-json'

const TopicSqlPlusPlus = () => {
  useEffect(() => Prism.highlightAll([sql, json]), [])
  return (
    <div className="page--home m-8 mb-12">
      <h1 class="md:text-3xl text-2xl font-semibold mb-3">N1QL and SQL++</h1>
      <hr />
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-0 pt-4 px-0 lg:pt-5 mb-5">
        <div class="rounded overflow-hidden shadow-lg my-4 md:my-6 lg:mr-6 mb-4 border-gray-400 border">
          <div class="md:px-6 md:py-3 py-2 px-4">
            <h2 class="font-bold lg:text-3xl text-2xl md:my-3 my-2">
              N1QL
            </h2>
            <p class="text-gray-700 text-base font-light mb-6">
              <strong>N1QL</strong> is Couchbase's implementation of the 
              <a rel="noopener noreferrer" href="https://www.couchbase.com/sqlplusplus" class="blue-link">SQL++ query language</a> 
              (or <strong>"SQL for JSON"</strong>). It is <strong>SQL-89</strong> compatible, which means that most of your SQL queries also work on N1QL:
            </p>
            <pre className="language-sql pre codeblock line-numbers prism">
              <code className="sql">
                {`SELECT t.relation COUNT(*) AS count, AVG(c.age) AS avg_age
                FROM  tutorial t
                UNNEST t.children c
                WHERE c.age > 10
                GROUP BY t.relation
                HAVING COUNT(*) > 1
                ORDER BY avg_age DESC
                LIMIT 1 OFFSET 1`}
              </code>
            </pre>
            <div class="flex mt-4 mb-2 justify-center">
              <a style={{ color: '#fff' }} target="_blank" rel="noopener noreferrer"
                class="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded text-center"
                href="https://query-tutorial.couchbase.com/"
              >
                Try the Online N1QL Tutorial <FaChevronRight style={{  marginTop: '-2px', fontSize: 'large', display: 'inline-block' }} />
              </a>
            </div>
          </div>
        </div>

        <div class="rounded overflow-hidden shadow-lg my-4 md:my-6 lg:ml-6 mb-4 border-gray-400 border">
          <div class="px-6 py-2">
            <h2 class="font-bold lg:text-3xl text-2xl mb-3 mt-3">
              N1QL Index Advisor
            </h2>
            <div class="text-gray-700 text-base font-light">
              The <a class="blue-link" rel="noopener noreferrer" href="https://blog.couchbase.com/n1ql-index-advisor-improve-query-performance-and-productivity/">N1QL Index Advisor</a> helps you to find the right index
              for your query. You can access it via the Web Console or try its online version:

              <div class="bg-gray-900 rounded p-5 text-purple-400 font-light text-sm mb-3 overflow-auto mt-6 h-56">
                <pre className="language-json pre codeblock">
                  <code className="json">
                    {`...
                    "adviseinfo": {
                    "current_indexes": [
                      {
                        "index_statement": "CREATE PRIMARY INDEX #primary ON 'tutorial'",
                        "keyspace_alias": "tutorial_t"
                      }
                    ],
                    "recommended_indexes": {
                      "covering_indexes": [
                        {
                          "index_property": "GROUPBY & AGGREGATES pushdown",
                          "index_statement": "CREATE INDEX adv_ALL_children_age_relation ON 'tutorial'(ALL ARRAY 'c'.'age' FOR c IN 'children' END,'relation')",
                          "keyspace_alias": "tutorial_t"
                        }
                      ],
                    "indexes": [
                      {
                        "index_statement": "CREATE INDEX adv_ALL_children_age ON 'tutorial'(ALL ARRAY 'c'.'age' FOR c IN 'children' END)",
                        "keyspace_alias": "tutorial_t",
                        "recommending_rule": "Index keys follow order of predicate types: 1. leading array index for unnest."
                      }
                    ]
                    }`}
                  </code>
                </pre>
              </div>
            </div>

            <div class="flex mt-4 mb-2 justify-center">
              <a style={{ color: '#fff' }} target="_blank" rel="noopener noreferrer"
                class="btn-gradient-default hover:bg-red-700 bg-red-600 visited:text-red-600 text-white font-normal px-4 py-2 mb-2 rounded text-center"
                href="https://index-advisor.couchbase.com/"
                >
                  Go To Index Advisor <FaChevronRight style={{  marginTop: '-2px', fontSize: 'large', display: 'inline-block' }} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center text-xl text-white p-4 rounded">
        <a
          target="_blank"
          rel="noopener noreferrer"
          class="w-full hover:text-white  hover:no-underline bg-blue-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded text-center"
          href="https://docs.couchbase.com/files/Couchbase-N1QL-CheatSheet.pdf"
        >
          Download the N1QL Cheat Sheet &nbsp; <FaCloudDownloadAlt style={{ marginTop: '-3px', fontSize: 'xx-large', display: 'inline-block' }} />
        </a>
      </div>

      <div class="md:mt-10 mt-6">
        <h2 class="font-bold text-2xl mb-3 mt-3">
          N1QL Quick Reference Cards
        </h2>

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-0 md:my-5 mb-3">
          <div class="rounded overflow-hidden  my-4 md:my-6 lg:mr-6 border-gray-400 border md:p-5 p-4">
            <h3 class="text-xl mb-3">N1QL</h3>
            <hr />
            <p class="text-gray-700 text-base">
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/select-syntax.html">
                <span style={{ color: '#e53e3e' }}>SELECT</span> syntax
              </a>&nbsp;|&nbsp;
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/selectintro.html">
                overview
              </a>&nbsp;|&nbsp;
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/selectclause.html">
                grammar
              </a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/join.html">
                <span style={{ color: '#e53e3e' }}>JOIN</span> syntax
              </a><br />
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/insert.html">
                <span style={{ color: '#e53e3e' }}>INSERT</span>
              </a>&nbsp;|&nbsp;
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/update.html">
                <span style={{ color: '#e53e3e' }}>UPDATE</span>
              </a>&nbsp;|&nbsp;
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/delete.html">
                <span style={{ color: '#e53e3e' }}>DELETE</span> syntax
              </a><br />
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/upsert.html">
                <span style={{ color: '#e53e3e' }}>UPSERT</span>
              </a>&nbsp;|&nbsp;
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/merge.html">
                <span style={{ color: '#e53e3e' }}>MERGE</span> syntax
              </a><br />
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/datatypes.html">Data types</a><br />
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/operators.html">Operators</a><br />
              <a class="ml-6" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/collectionops.html">
                <span style={{ color: '#e53e3e' }}>ANY</span>,&nbsp;
                <span style={{ color: '#e53e3e' }}>SOME</span>,&nbsp;
                <span style={{ color: '#e53e3e' }}>EVERY</span> syntax
              </a><br />
              <a class="ml-6" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/collectionops.html">
                <span style={{ color: '#e53e3e' }}>CASE</span>,&nbsp;
                <span style={{ color: '#e53e3e' }}>WHEN</span>,&nbsp;
                <span style={{ color: '#e53e3e' }}>THEN</span> syntax
              </a><br />
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/functions.html">Functions</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/arrayfun.html">Array</a>
              and <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/objectfun.html">Object functions</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/datefun.html">Date</a>
              and <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/stringfun.html">String functions</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/typefun.html">Type functions</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/userfun.html">User defined functions</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/windowfun.html">Window functions</a> and 
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/functions.html">Functions Overview</a><br />
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/subqueries.html">Subqueries</a>
              and <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/correlated-subqueries.html">Correlated Subqueries</a><br />
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/createindex.html">
                <span style={{ color: '#e53e3e' }}>CREATE INDEX</span>
              </a> syntax
            </p>
          </div>

          <div class="rounded overflow-hidden my-4 md:my-6 lg:ml-6 border-gray-400 border md:p-5 p-4">
            <h3 class="text-xl mb-3">N1QL for analytics</h3>
            <hr />
            <p class="text-gray-700 text-base">
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/analytics/1_intro.html">N1QL language reference for Analytics</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/analytics/2_expr.html">Analytics expressions</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/analytics/3_query.html">Analytics query syntax</a> and 
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/analytics/4_error.html">errors</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/analytics/5_ddl.html">Analytics data definition language (DDL)</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/analytics/appendix_1_keywords.html">Analytics keywords</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/analytics/9_data_type.html">Analytics data types</a><br />
              <a rel="noopener noreferrer" class="ml-6" href="https://docs.couchbase.com/server/current/analytics/8_builtin.html">Analytics builtin functions</a><br />
            </p>
          </div>
        </div>
      </div>

      <div class="flex justify-center text-xl text-white p-4 rounded">
        <a
          target="_blank"
          rel="noopener noreferrer"
          class="w-full hover:text-white hover:no-underline bg-blue-600 visited:text-red-600 text-white font-norma px-4 py-2 mb-2 rounded text-center"
          href="https://forums.couchbase.com/c/n1ql/"
          >
            Didn't find what you were looking for? Ask a question on Couchbase's Forum &nbsp; <FaExternalLinkAlt style={{ marginTop: '-3px', fontSize: 'x-large', display: 'inline-block' }} />
        </a>
      </div>

      <div class="mt-6">
        <h2 class="font-bold text-2xl mb-3 mt-3">
          Featured Videos
        </h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:my-5 ">
          <div class="lg:max-w-full md:max-w-xl rounded overflow-hidden shadow-lg">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe title="Query Language Evolution" class="w-full block h-56" src="https://www.youtube.com/embed/LAlDe1w7wxc" frameborder="0" allowfullscreen ></iframe>
            </div>

            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">Query Language Evolution</div>
              <p class="text-gray-700 text-base font-light">
                In this panel, Mike Carey, UC Irvine Bren Professor of Computer and Information Sciences and Don Chamberlin, Co-Inventor of SQL discuss about how query is evolving to adapt to modern database demands, and what role SQL++ will have in the future of hybrid operational/analytical computing.
              </p>
            </div>
          </div>

          <div class="lg:max-w-full md:max-w-xl rounded overflow-hidden shadow-lg">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe title="CB110 - N1QL Online Course" class="w-full block h-56" src="https://www.youtube.com/embed/BHUkxsPWnm0" frameborder="0" allowfullscreen style={{ display: 'block', marginLeft: 'auto' }}></iframe>
            </div>

            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">CB110 - N1QL Online Course</div>
              <p class="text-gray-700 text-base font-light">
                This free tutorial training, comprised of numerous video tutorials, demonstrations, and hands on lab exercises, teaches how to index, select, filter, aggregate, and calculate JSON data fields, and how to insert, update upsert, join, and delete JSON documents, within a Couchbase data bucket.
              </p>
            </div>
          </div>

          <div class="lg:max-w-full md:max-w-xl rounded overflow-hidden shadow-lg ">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe title="N1QL+GSI: Language and Performance Improvements" class="w-full block h-56" src="https://www.youtube.com/embed/IAjyranN7cw" frameborder="0" allowfullscreen style={{ display: 'block', marginLeft: 'auto' }}></iframe>
            </div>

            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">N1QL+GSI: Language and Performance Improvements</div>
              <p class="text-gray-700 text-base font-light">
                Couchbase Server 5.0 has language and performance improvements for pagination, index exploitation, integration, index availability, and more. Couchbase Server 5.5 will offer even more language and performance features for N1QL and global secondary indexes (GSI), including ANSI joins, aggregate performance, index partitioning, auditing, and more. We’ll give you an overview of the new features as well as practical use case examples.
              </p>
            </div>
          </div>

          <div class="lg:max-w-full md:max-w-xl rounded overflow-hidden shadow-lg ">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe title="Introduction to SQL++ for Big Data: Same Language, More Power" class="w-full block h-56" src="https://www.youtube.com/embed/B8-TxA-nbLc" frameborder="0" allowfullscreen style={{ display: 'block', marginLeft: 'auto' }}></iframe>
            </div>

            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">Introduction to SQL++ for Big Data: Same Language, More Power</div>
              <p class="text-gray-700 text-base font-light">
                Developer Advocate Matthew D. Groves talks about the history of SQL, its limitations and why SQL++ is necessary. This session is a gentle introduction to the key SQL++ concepts.
              </p>
            </div>
          </div>

          <div class="lg:max-w-full md:max-w-xl rounded overflow-hidden shadow-lg">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe title="Testing N1QL workloads with Apache JMeter" class="w-full block h-56" src="https://www.youtube.com/embed/8SWLRvWcpwM" frameborder="0" allowfullscreen style={{ display: 'block', marginLeft: 'auto' }}></iframe>
            </div>

            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">Testing N1QL workloads with Apache JMeter</div>
              <p class="text-gray-700 text-base font-light">
                In this short video you will learn how to stress test your N1QL workloads with Apache JMeter.
              </p>
            </div>
          </div>

          <div class="lg:max-w-full md:max-w-xl rounded overflow-hidden shadow-lg">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe title="N1QL for Analytics: A Tutorial" class="w-full block h-56" src="https://www.youtube.com/embed/CmE_Y0YuzIE" frameborder="0" allowfullscreen style={{ display: 'block', marginLeft: 'auto' }}></iframe>
            </div>

            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">N1QL for Analytics: A Tutorial</div>
              <p class="text-gray-700 text-base font-light">
                The session provides a gentle introduction to N1QL for Analytics, the first commercial implementation of SQL++. We will cover the language features supported by the Couchbase Analytics Service and show you how easy it is for SQL users to transition to SQL++.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
export default TopicSqlPlusPlus